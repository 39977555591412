import React, {useState} from 'react'
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}subscribe`, {email, name, message});
      console.log(resp.data);
      toast.success('Thank you for getting in touch!', {theme: "colored"})
    } catch (error) {
      console.log(error.response);
      toast.error('Something went wrong. Please try again', {theme: "colored"})
    }
  };
  return (
    <div id="" className='videoColor'>
    <div className='px-4 md:px-1 md:pl-12 pt-1 relative'>
        <div className='text-white pt-8 pb-4 md:pt-16 md:pb-8 text-center font-medium text-2xl md:text-3xl'>Let us inform you about everything important directly.</div>

        <form className='flex flex-col justify-center items-center' onSubmit={handleSubmit}>
            <input type="name" id="name" value={name} onChange={(e) => setName(e.target.value)} class="mb-4 placeholder-white bg-transparent rounded-lg border border-white-300 text-white text-base focus:ring-white focus:border-white block w-4/5  md:w-2/5 p-3" placeholder="Full Name" required></input>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="mb-4 placeholder-white bg-transparent rounded-lg border border-white-300 text-white text-base focus:ring-white focus:border-white block w-4/5  md:w-2/5 p-3" placeholder="Email Address" required></input>
            <textarea type="message" id="message" value={message} onChange={(e) => setMessage(e.target.value)} class="mb-4 placeholder-white bg-transparent rounded-lg border border-white-300 text-white text-base focus:ring-white focus:border-white block w-4/5  md:w-2/5 p-3" placeholder="Message" required></textarea>

            <button type="submit" name='submit' value="submit" class="text-white bg-primaryColor hover:bg-primaryColor focus:ring-4 focus:outline-none focus:ring-white font-medium text-sm w-28 h-12 p-2 text-center inline-flex items-center">
            <p className='text-base font-normal mr-1'>Submit</p>
            <svg className="w-8 h-5" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
            </button>
        </form>
    </div>
    <ToastContainer />
    </div>
  )
}

export default Newsletter