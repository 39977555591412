import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import 'swiper/css';

const partnersAnimation = {
  visible: { scale: 1, opacity: 1, transition: { delay: 0.3, duration: 0.7, type: "tween" } },
  hidden: { scale: 0, opacity: 0 },
}

const OurClients = (props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
      if(inView) {
          controls.start("visible");
      }
  }, [controls, inView]);

  return (
    <div className='w-full mx-auto'>
    <h1 className='py-10 text-center font-semibold text-2xl md:text-3xl text-greenColor'>Our Clients</h1>

    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={partnersAnimation}
      className='mt-11 md:max-w-7xl mx-auto px-4 md:px-0'
    >
        <Swiper
        spaceBetween={50}
        slidesPerView={5}
        loop={true}
        autoplay={{
            delay: 1000,
            disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          // when window width is >= 640px
          300: {
            slidesPerView: 2,
          },
          // when window width is >= 768px
          640: {
            // width: 768,
            slidesPerView: 5,
          },
        }}
        >
          {props.values?.map((value) => 
                <SwiperSlide key={value.id}><img src={value.featured_image} alt={value.name} /></SwiperSlide>
          )}
        </Swiper>
    </motion.div>

    <div className='py-10'>

    </div>

    </div>

    
  )
}

export default OurClients