import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import striptags from "striptags";

import { Link } from "react-router-dom"; 

const objAnimation = {
  visible: { y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.7, type: "tween" } },
  hidden: { y: 50, opacity: 0 },
}

const OnlineProgram = (props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
      if(inView) {
          controls.start("visible");
      }
  }, [controls, inView]);

  return (
    <div id="courses" className='mt-0 w-full bg-gray-50 mx-auto'>
    <h1 className='text-greenColor py-6 md:py-10 text-center font-semibold text-2xl md:text-3xl'>Online Programs</h1>
 
    <motion.div 
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={objAnimation}
    className='max-w-6xl mx-auto grid grid-rows-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
        {props.values?.slice(0, 3).map((value) => 
                   <div key={value.id} className='bg-[#fff] p-3 rounded-md shadow-lg hover:shadow-2xl'>
                   <img className='w-full h-52 object-cover mx-auto rounded-md' src={value.featured_image} alt={value.slug} />
                   <h3 className='text-contentTextColor mt-5 font-semibold text-xl'>{value.name}</h3>
                   <p className='text-contentTextColor mt-2 font-medium text-base leading-normal'>{striptags(value.description.substring(0, 100))}...</p>
                   <Link to={`/onlineprogram/${value.slug}`}>
                    <button type="button" className="inline-flex items-center text-base font-medium mt-4 text-primaryColor bg-none border-transparent hover:px-4 hover:py-1 hover:rounded-3xl focus:border-transparent focus:ring-0">
                            Read More
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                      </button>
                   </Link>

               </div> 
        )}
    </motion.div>

    <div className='my-5 max-w-7xl flex justify-end'>
    <Link to="/onlineprogram">
    <button className='self-center mt-2 px-6 py-2 bg-primaryColor rounded-full font-medium text-sm'>
      View More
    </button>
    </Link>
    </div>

    <div className='py-4'>

    </div>
    </div>
  )
}

export default OnlineProgram