import React, { useState, useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const palcementAnimation = {
        visible: { scale: 1, opacity: 1, transition: { delay: 0.3, duration: 0.7, type: "tween" } },
        hidden: { scale: 0, opacity: 0 },
}

const scholorshipPerRow = 4;

const Placements = (props) => {
        const controls = useAnimation();
        const [ref, inView] = useInView();
      
        useEffect(() => {
            if(inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const [next, setNext] = useState(scholorshipPerRow);

        const handleMoreImage = () => {
          setNext(next + scholorshipPerRow);
        }
  return (
    <div className='mt-6 w-full bg-gray-50 mx-auto'>
    <h1 className='text-greenColor py-10 text-center font-semibold text-2xl md:text-3xl'>Successful Student Visa </h1>

        <motion.div 
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={palcementAnimation}
        className='max-w-6xl mx-4 md:mx-auto grid grid-rows-1 md:grid-cols-2 lg:grid-cols-4 gap-8'
        >

        {props.values?.slice(0, next)?.map((value) => 
                <div key={value.id} className='bg-[#fff] p-3 rounded-md shadow-lg hover:shadow-2xl'>
                        <img className='w-full h-52 object-cover mx-auto rounded-md' src={value.featured_image} alt={value.slug} />
                        <h3 className='text-contentTextColor mt-4 font-semibold text-xl text-center'>{value.title}</h3>
                        <p className='text-contentTextColor mt-1 font-semibold text-base text-center'>{value.college}</p>
                        <p className='text-contentTextColor mt-0 font-semibold text-base text-center'>{value.sub_title}</p>

                </div>
        )}
    </motion.div>

        <div className='flex justify-center'>
              {next < props.values?.length && (
              <button
                className="self-center mt-8 px-6 py-2 bg-primaryColor rounded-full font-medium text-sm"
                onClick={handleMoreImage}
              >
                Load More
              </button>
              )}
        </div>

    <div className='py-6'>

    </div>
    </div>
  )
}

export default Placements