import React from "react";
// import cardImage from '../assets/cardImage.png'
import { Link } from "react-router-dom";

const AppointmentCard = () => {
  return (
    <div className="mt-12 mb-12 flex items-center justify-center md:mb-12">
      <div className="max-w-md md:max-w-5xl px-5">
        <div className="bg-[#E5EADA] shadow-xl rounded-lg overflow-hidden md:flex">
          <div
            className="bg-cover bg-bottom h-56 md:h-auto md:w-2/4"
            style={
              {
                // backgroundImage: `url(${cardImage})`
              }
            }
          ></div>
          <div>
            <div className="p-4 md:p-4">
              <p className="text-2xl font-semibold mx-4 text-greenColor mt-5 md:text-3xl md:mx-8 tracking-normal">
                Let us make you feel the difference and let’s create a
                successful story together.
              </p>
            </div>
            <div className="mx-10 p-4 bg-[#E5EADA] flex justify-end md:p-5 md:mx-10">
              <Link to="immigrationprogram">
                <button
                  type="button"
                  className="self-center px-6 py-2 bg-primaryColor rounded-full font-medium text-sm"
                >
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
