import React, { useState, useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import ApiFetch from "../ApiFetch";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let enrollDescription = {};
const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
if (!isMobile) {
  enrollDescription = {
    visible: {
      x: 0,
      opacity: 1,
      transition: { delay: 0.3, duration: 0.8, type: "tween" },
    },
    hidden: { x: -50, opacity: 0 },
  };
} //if the width >= 768px, boxVariants will be empty, resulting in no animation
//you need to refresh the page, it doesn't work when you resize it!

const enrollForm = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { delay: 0.3, duration: 0.8, type: "tween" },
  },
  hidden: { x: 50, opacity: 0 },
};

const ImmigrationProgram = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [CRS, setCRS] = useState("");
  const [course, setCourse] = useState("");
  const [IELTS, setIELTS] = useState("");

  const controls = useAnimation();
  const [ref, inView] = useInView();

  // const [data, setData] = useState({});
  // const [loading, setLoading] = useState(true);

  const { data, loading } = ApiFetch();

  // const fetchData = async () => {
  //     try {
  //       const { data: response } = await axios.get(`${process.env.REACT_APP_BASE_URL}`);
  //       setData(response);
  //     } catch (error) {
  //       console.error(error)
  //     }
  //     setLoading(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email, name, phone, course)
    try {
      const resp = await axios.post(
        `https://admin.athena.com.np/api/home/contact_form`,
        { name, email, phone, CRS, IELTS, course }
      );
      console.log(resp.data);
      toast.success("Information Successfully Sent", { theme: "colored" });
    } catch (error) {
      console.log(error.response);
      toast.error("Something went wrong. Please try again", {
        theme: "colored",
      });
    }
  };

  // useEffect(() => {
  //     fetchData();
  // }, []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      {loading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ClipLoader size={100} color="#007000" loading />
        </div>
      )}

      {!loading && (
        <div id="contact" className="w-full mx-auto">
          <div className="py-4 mb-4 max-w-4xl mx-auto lg:py-16">
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={enrollForm}
              className="mt-[80px] px-8 py-6 mx-auto bg-greenColor rounded-md shadow-xl"
            >
              <h1 className="text-gray-100 font-semibold text-center mb-6 text-2xl md:text-3xl">
                Contact us for Consultation, PR & Student Visa
              </h1>
              <form
                className="flex flex-col items-stretch"
                onSubmit={handleSubmit}
              >
                <div class="grid xl:grid-cols-2 xl:gap-6">
                  {/* name */}
                  <div class="mb-6">
                    <label
                      for="name"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  {/* email */}
                  <div class="mb-6">
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>

                <div class="grid xl:grid-cols-2 xl:gap-6">
                  {/* mobile */}
                  <div class="mb-6">
                    <label
                      for="mobile"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Mobile Number"
                      required
                    />
                  </div>

                  {/* CRS */}
                  <div class="mb-6">
                    <label
                      for="crs"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      CRS Score
                    </label>
                    <input
                      type="text"
                      id="crs"
                      value={CRS}
                      onChange={(e) => setCRS(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="CRS Score"
                    />
                  </div>
                </div>

                <div class="grid xl:grid-cols-2 xl:gap-6">
                  {/* WES */}
                  <div class="mb-6">
                    <label
                      for="course"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      WES Equivalence
                    </label>
                    <select
                      id="course"
                      onChange={(e) => setCourse(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    >
                      {data.course_types.map((value) => (
                        <option
                          className="text-base font-medium"
                          value={value.id}
                          key={value.id}
                        >
                          {value.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* IELTS */}
                  <div class="mb-6">
                    <label
                      for="score"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      IELTS Score
                    </label>
                    <input
                      type="text"
                      id="score"
                      value={IELTS}
                      onChange={(e) => setIELTS(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="IELTS Score"
                      required
                    />
                  </div>
                </div>
                {/* <div class="mb-6">
                        <label for="course" class="block mb-2 text-sm font-medium text-gray-300">Course Type</label>
                        <select id="course" onChange={(e) => setCourse( e.target.value )} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">

                        {props.values.map((value) => 
                            <option value={value.id} key={value.id}>{value.title}</option>
                        )}

                        </select>
                        </div> */}
                <button
                  type="submit"
                  name="submit"
                  value="submit"
                  className="self-center mt-2 px-6 py-2 bg-primaryColor rounded-full font-medium text-sm"
                >
                  I am Interested
                </button>
              </form>
              <p className="text-white text-base font-medium text-center mt-6">
                Calculate Your CRS Score:{" "}
                <a
                  className="hover:font-bold hover:text-base"
                  target="_blank"
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/criteria-comprehensive-ranking-system/grid.html
    "
                >
                  Click here
                </a>
              </p>
            </motion.div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default ImmigrationProgram;
