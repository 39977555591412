import React from "react";
import "../App.css";
import Enroll from "../components/Enroll";
// import Appointment from '../components/Appointment';
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Objectives from "../components/Objectives";
import OurCourses from "../components/OurCourses";
import Partners from "../components/Partners";
import Placements from "../components/Placements";
import Trainers from "../components/Trainers";
import VideoTutorial from "../components/VideoTutorial";
import WhyUs from "../components/WhyUs";
import ApiFetch from "../ApiFetch";

import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import About from "../components/About";
import OnlineProgram from "../components/OnlineProgram";
import Gallery from "../components/Gallery";
import OurClients from "../components/OurClients";
import FlipCard from "../components/FlipCard";
import Dropdown from "../components/Dropdown";
import AppointmentCard from "../components/AppointmentCard";

function Home() {
  const { data, loading } = ApiFetch();
  // console.log("Home", data.banners);
  return (
    <>
      {loading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ClipLoader size={100} color="#007000" loading />
        </div>
      )}

      {!loading && (
        <motion.div className="wrapper" initial="hidden" animate="show">
          <Hero values={data.banners} />
          <About values={data.aboutus} />
          <OurCourses values={data.int_programs} />
          <Partners values={data.partners} />
          <OnlineProgram values={data.on_programs} />
          <AppointmentCard />
          <Gallery values={data.look_books} />
          <OurClients values={data.partners} />
          {/* <Placements values={data.placements} /> */}
          <Newsletter />
        </motion.div>
      )}
    </>
  );
}

export default Home;
