import React, {useState, useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from 'axios'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let enrollDescription = {};
const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
if (!isMobile) {
    enrollDescription = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8, type: "tween" } },
    hidden: { x: -50, opacity: 0 },
  };
} //if the width >= 768px, boxVariants will be empty, resulting in no animation
//you need to refresh the page, it doesn't work when you resize it!

const enrollForm = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8, type: "tween" } },
    hidden: { x: 50, opacity: 0 },
}

const Enroll = (props) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [crs, setCrs] = useState('')
    const [course, setCourse] = useState('');
    const [wes, setWes] = useState('')
    const [ielts, setIelts] = useState('');

    const controls = useAnimation();
    const [ref, inView] = useInView();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(email, name, phone, course)
        try {
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}contact_form`, {name, email, phone, course});
            console.log(resp.data);
            toast.success('Information Successfully Sent', {theme: "colored"})
        } catch (error) {
            console.log(error.response);
            toast.error('Something went wrong. Please try again', {theme: "colored"})
        }
    }
  
    useEffect(() => {
        if(inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

  return (
    <div id="enroll" className='w-full mx-auto'>
        <div className='py-4 mb-12 max-w-6xl mx-auto lg:space-x-28 flex flex-col lg:py-16 lg:flex-row'>

            <motion.div
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={enrollDescription}
                className='px-4 lg:w-3/5'
            >
                <h1 className='text-3xl text-headingTextColor font-semibold mt-10 mb-6 leading-relaxed tracking-normal md:text-4xl'>Contact Us Now For a Free Consultation</h1>
                <p className='text-contentTextColor text-lg font-normal leading-relaxed tracking-normal'>Choosing and suggesting the best university is another pillar of this organization. We are constantly trying to become the bridge between what students are seeking and how to get to the destination they’re dreaming. The major part covered by Athena also is the awareness about the Scholarship Schemes that is provided all over the world.
                </p>

                <p className='text-contentTextColor text-lg font-normal leading-relaxed tracking-normal'>
                Apart from that, we also provide the pathways and career counselling to people who are planning to Migrate or Immigrate so that they can get the best future. We also provide them the best suggestions of location with detailed information about the reason behind the suggestions.
                </p>
            </motion.div>

            <motion.div 
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={enrollForm}
                className='px-8 py-6 bg-greenColor rounded-md shadow-xl lg:w-2/5'
            >
                <h1 className='text-gray-100 font-normal text-2xl text-center mb-4'>Book Your Appointment</h1>
                <form className="flex flex-col items-stretch" onSubmit={handleSubmit}>
                    {/* name */}
                        <div class="mb-6">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-300">Name</label>
                            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Full Name" required />
                        </div>

                        {/* email */}
                        <div class="mb-6">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-300">Your email</label>
                            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Email Address" required />
                        </div>

                        {/* mobile */}
                        <div class="mb-6">
                            <label for="mobile" class="block mb-2 text-sm font-medium text-gray-300">Mobile Number</label>
                            <input type="text" id="mobile" value={phone} onChange={(e) => setPhone(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Mobile Number" required />
                        </div>

                        {/* CRS */}
                        <div class="mb-6">
                            <label for="crs" class="block mb-2 text-sm font-medium text-gray-300">CRS Score</label>
                            <input type="text" id="crs" value={crs} onChange={(e) => setCrs(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="CRS Score" required />
                        </div>

                    {/* WES */}
                    <div class="mb-6">
                    <label for="wes" class="block mb-2 text-sm font-medium text-gray-300">WES Equivalence</label>
                    <select id="wes" onChange={(e) => setWes( e.target.value )} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option>Select</option>
                    <option>Secondary school (high school) credential or less</option>
                    <option>Post-secondary program credential of one year or longer</option>
                    <option>Two or more post-secondary program credentials</option>
                    <option>Master’s level or above</option>
                    <option>Doctoral level</option>
                    </select>
                    </div>

                    {/* IELTS */}
                    <div class="mb-6">
                    <label for="ielts" class="block mb-2 text-sm font-medium text-gray-300">IELTS Score</label>
                    <select id="ielts" onChange={(e) => setIelts( e.target.value )} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option>Select</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    </select>
                    </div>

                    {/* <div class="mb-6">
                    <label for="course" class="block mb-2 text-sm font-medium text-gray-300">Course Type</label>
                    <select id="course" onChange={(e) => setCourse( e.target.value )} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">

                    {props.values.map((value) => 
                        <option value={value.id} key={value.id}>{value.title}</option>
                    )}

                    </select>
                    </div> */}
                    <button type="submit" name='submit' value="submit" className='self-center mt-2 px-6 py-2 bg-primaryColor rounded-full font-medium text-sm'>I am Interested</button>
                </form>
                <p className="text-white text-base text-center mt-6">Calculate Your CRS Score: <a target="_blank" href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/criteria-comprehensive-ranking-system/grid.html
'>Click here</a></p>
            </motion.div>
        </div>
    </div>
  )
}

export default Enroll