import React from 'react'
import Newsletter from '../components/Newsletter'

const Contact = () => {
  return (
    <div className='pt-[80px]'>
      <Newsletter />
    </div>
  )
}

export default Contact