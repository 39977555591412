import React from "react";
import ApiFetch from "../ApiFetch";
import { ClipLoader } from "react-spinners";
import striptags from "striptags";

const About = () => {
  const { data, loading } = ApiFetch();
  return (
    <>
      {loading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ClipLoader size={100} color="#007000" loading />
        </div>
      )}

      {!loading && (
        <div className="pt-[80px] max-w-6xl mx-auto">
          <h1 className="my-8 text-center text-greenColor font-semibold text-2xl md:text-3xl">
            About Us
          </h1>
          <img
            className="w-[90vw] h-auto md:w-[80vw] md:h-[70vh] object-cover mx-auto rounded-xl shadow-2xl"
            src={data.aboutus?.featured_image}
            alt={data.aboutus?.slug}
          />
          <p className="my-16 max-w-5xl mx-4 md:mx-auto text-contentTextColor text-base font-medium leading-relaxed">
            {striptags(data.aboutus?.description)}
          </p>
        </div>
      )}
    </>
  );
};

export default About;
