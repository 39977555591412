import { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import striptags from "striptags";
import { useParams } from "react-router-dom";

const OnlineCourseDetail = () => {
  const CourseSlug = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const param = CourseSlug.programId;

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        `https://admin.athena.com.np/api/online_program/details/${param}`
      );
      setData(response.details);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ClipLoader size={100} color="#007000" loading />
        </div>
      )}

      {!loading && (
        <div className="pt-[120px] max-w-6xl mx-auto">
          <img
            className="w-[90vw] h-auto md:w-[70vw] md:h-[60vh] object-cover mx-auto rounded-xl shadow-2xl"
            src={data?.featured_image}
            alt={data?.slug}
          />
          <h1 className="my-8 text-greenColor font-semibold text-2xl md:text-3xl max-w-5xl mx-4 md:mx-auto">
            {data?.name}
          </h1>
          <p className="mb-16 max-w-5xl mx-4 md:mx-auto text-contentTextColor text-base font-medium leading-relaxed">
            {striptags(data?.description)}
          </p>
        </div>
      )}
    </>
  );
};

export default OnlineCourseDetail;
