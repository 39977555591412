import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import striptags from "striptags";
import { Link } from "react-router-dom";

let aboutDescription = {};
const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
if (!isMobile) {
    aboutDescription = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8, type: "tween" } },
    hidden: { x: -50, opacity: 0 },
  };
} //if the width >= 768px, boxVariants will be empty, resulting in no animation
//you need to refresh the page, it doesn't work when you resize it!

const aboutImg = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8, type: "tween" } },
    hidden: { x: 50, opacity: 0 },
}

const About = (props) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
        if(inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

  return (
    // <div id="enroll" className='w-full bg-gray-50 mx-auto'>
    <>
{/* {props.values.map((value) =>  */}
    <div id="about" className='py-4 mb-12 max-w-6xl mx-auto mt-6 lg:mt-0 lg:space-x-28 flex flex-col items-center lg:py-8 lg:flex-row'>
        <motion.div 
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={aboutDescription}
        className='lg:w-3/5'>
            <h3 className='text-2xl text-greenColor font-semibold leading-tight md:text-3xl py-3'>About Us</h3>
            <p className='text-contentTextColor text-base font-medium leading-relaxed'>{striptags(props.values?.description)}</p>
            <Link to='/about'>
            <button className='mt-4 self-center px-6 py-2 bg-primaryColor rounded-full font-medium text-sm text-white'>
            Read More
            </button>
            </Link>
        </motion.div>

        <motion.div 
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={aboutImg}
        className='lg:w-2/5 mt-8 lg:mt-0'>
            <img className='mx-auto object-cover h-96 w-96 border rounded-lg shadow-xl hover:shadow-2xl' src={props.values?.featured_image} alt="about-img" />
        </motion.div>
    </div>
{/* )} */}
    </>

    // </div>
  )
}

export default About