import { useEffect, useState } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import { ClipLoader } from "react-spinners";

import g1 from "../assets/gallery1.jpg";
import g2 from "../assets/gallery2.jpg";
import g3 from "../assets/gallery3.jpg";
import g5 from "../assets/gallery5.jpg";
import g6 from "../assets/gallery6.jpg";
import g7 from "../assets/g7.jpg";

const objAnimation = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.3, duration: 0.7, type: "tween" },
  },
  hidden: { y: 50, opacity: 0 },
};

const images = [g1, g2, g3, g5, g6, g7];

const Gallery = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        `https://admin.athena.com.np/api/look_book/`
      );
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }

    fetchData();
  }, [controls, inView]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  //looping through our images array to create img elements
  // const imageCards = data.look_books.map((image) => (
  //     <img key={image.id} className="block object-cover object-center w-full h-full rounded-lg" onClick={() => showImage(image)} src={featured_image} alt={title} />
  //   ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };

  return (
    <>
      {loading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ClipLoader size={100} color="#007000" loading />
        </div>
      )}

      {!loading && (
        <div className="pt-[80px] bg-gray-50">
          <h1 className="my-8 text-center text-greenColor font-semibold text-2xl md:text-3xl">
            Gallery
          </h1>

          <div className="overflow-hidden text-gray-700">
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={objAnimation}
              className="container px-5 py-2 mx-auto lg:px-32"
            >
              <div className="pb-24 grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
                {/* {imageCards} */}
                {data.look_books?.map((image) => (
                  <img
                    key={image.id}
                    className="block object-cover object-center w-full h-full rounded-lg"
                    onClick={() => showImage(image.featured_image)}
                    src={image.featured_image}
                    alt={image.title}
                  />
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      )}

      {lightboxDisplay ? (
        <div id="lightbox" onClick={hideLightBox}>
          <button className="gallery-lightbox-btn" onClick={showPrev}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 md:h-12 md:w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <img id="lightbox-img" src={imageToShow}></img>
          <button className="gallery-lightbox-btn" onClick={showNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 md:h-12 md:w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Gallery;
