import React from "react";
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import About from "./pages/About";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import OnlineProgram from "./pages/OnlineProgram";
import Contact from "./pages/Contact";
import InternationalProgram from "./pages/InternationalProgram";
import ImmigrationProgram from "./pages/ImmigrationProgram";
import StudentVisa from "./pages/StudentVisa";
import Gallery from "./pages/Gallery";
import OnlineCourseDetail from "./pages/OnlineCourseDetail";
import InternationProgramCourseDetail from "./pages/InternationProgramCourseDetail";
import ApiFetch from './ApiFetch';
// import CourseDescription from "./pages/CourseDescription";
// import CourseD from "./pages/CourseD";

function App() {
  const {
    data,
    loading,
  } = ApiFetch();

  return (
    <BrowserRouter>
      <ScrollToTop>
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/onlineprogram" element={<OnlineProgram />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/internationalprogram" element={<InternationalProgram />} />
          <Route path="/immigrationprogram" element={<ImmigrationProgram />} />
          <Route path="/studentvisa" element={<StudentVisa />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/onlineprogram/:programId" element={<OnlineCourseDetail />} />
          <Route path="/internationalprogram/:programId" element={<InternationProgramCourseDetail />} />
          {/* <Route path="/courses/:courseId" element={<CourseD />} />  */}
        </Routes>
      <Footer values={data.site_settings} />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
