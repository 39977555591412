import { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import striptags from "striptags";
import { Link } from "react-router-dom";

const OnlineProgram = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        `https://admin.athena.com.np/api/online_program/`
      );
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading && (
        <div className="w-screen h-screen flex justify-center items-center">
          <ClipLoader size={100} color="#007000" loading />
        </div>
      )}

      {!loading && (
        <div className="pt-[80px] bg-gray-50">
          <h1 className="my-8 text-center text-greenColor font-semibold text-2xl md:text-3xl">
            Online Program
          </h1>

          <div className="pt-4 pb-24 max-w-6xl mx-auto grid grid-rows-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {data.on_program?.map((value) => (
              <div
                key={value.id}
                className="bg-[#fff] p-3 rounded-md shadow-lg hover:shadow-2xl"
              >
                <img
                  className="w-full h-52 object-cover mx-auto rounded-md"
                  src={value.featured_image}
                  alt={value.slug}
                />
                <h3 className="text-contentTextColor mt-5 font-semibold text-xl">
                  {value.name}
                </h3>
                <p className="text-contentTextColor mt-2 font-medium text-base leading-normal">
                  {striptags(value.description.substring(0, 100))}...
                </p>
                <Link to={`/onlineprogram/${value.slug}`}>
                  <button
                    type="button"
                    className="inline-flex items-center text-base font-medium mt-4 text-primaryColor bg-none border-transparent hover:px-4 hover:py-1 hover:rounded-3xl focus:border-transparent focus:ring-0"
                  >
                    Read More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 ml-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default OnlineProgram;
