import React, { useState, useEffect , useRef} from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// import { Link, Nav, animateScroll as scroll, } from 'react-scroll'

import { Link } from "react-router-dom";
import logo from '../assets/logo02.png';

import { MenuIcon, XIcon } from '@heroicons/react/outline';

const navAnimation = {
  visible: { y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8, type: "tween" } },
  hidden: { y: -20, opacity: 0 },
}

const Navbar = () => {

    const [nav, setNav] = useState(false)
    const [showOptions, setShowOptions] = useState(false);
    // const [current, setCurrent] = useState('');
    const handleClick = () => setNav(!nav)

    const handleDropdownClick = () => {
      setShowOptions(!showOptions);
    }    

    const handleClose =()=> setNav(!nav)

    const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
     };

     const controls = useAnimation();
     const [ref, inView] = useInView();
   
     useEffect(() => {
         if(inView) {
             controls.start("visible");
         }
     }, [controls, inView]);

    // useEffect(() => {
    //   process.browser && setCurrent(window.location.pathname);
    // }, [process.browser && window.location.pathname]);

    // const activeName = (name) => `${current === name && "active"}`;

  return (
    <motion.div 
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={navAnimation}
    className='w-screen h-[80px] z-50 bg-white fixed drop-shadow-lg'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex justify-between items-center w-full px-8 mx-auto space-x-44'>
          {/* <h1 className='text-3xl font-bold mr-4 sm:text-4xl'>BRAND.</h1> */}
          <Link to='/'>
            <a className='md:ml-0'>
                        {/* <span className="sr-only">RealGate Consultancy</span> */}
                        <img
                          alt="Athena Services"
                          className='h-16'
                          src={logo}
                          onClick={goToTop}
                        />
              </a>
            </Link>
            <ul className='hidden md:flex md:space-x-10'>
          <li className='font-semibold text-base text-contentTextColor'><Link to='/'>Home</Link></li>
          <li className='font-semibold text-base text-contentTextColor'><Link to='/about'>About Us</Link></li>
          {/* <li className='font-semibold text-base text-contentTextColor'><Link to='/onlineprogram'>Programs</Link></li> */}

          <div className="relative">
              <li onClick={handleDropdownClick} className='inline-flex items-center justify-center font-semibold text-base text-contentTextColor cursor-pointer hover:text-redColor'>
                Programs
                <svg className="-mr-1 ml-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
              </li>

            {showOptions && (
            <div ref={ref} className="absolute mt-3 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div className="py-1" role="none">
                {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                <Link to='/internationalprogram'><a onClick={handleDropdownClick} className='block px-4 py-2 font-semibold text-base text-contentTextColor hover:bg-gray-100' role="menuitem" tabindex="-1" id="menu-item-0">Training Programs</a></Link>
                <Link to='/onlineprogram'><a onClick={handleDropdownClick} className='block px-4 py-2 font-semibold text-base text-contentTextColor hover:bg-gray-100' role="menuitem" tabindex="-1" id="menu-item-1">Online Programs</a></Link>
                <Link to='/immigrationprogram'><a onClick={handleDropdownClick} className='block px-4 py-2 font-semibold text-base text-contentTextColor hover:bg-gray-100' role="menuitem" tabindex="-1" id="menu-item-1">Immigration Program</a></Link>
                {/* <Link to='/studentvisa'><a onClick={handleDropdownClick} className='block px-4 py-2 font-semibold text-base text-contentTextColor hover:bg-gray-100' role="menuitem" tabindex="-1" id="menu-item-1">Student Visa</a></Link> */}
            </div>
            </div>
        )}
          </div>
          <li className='font-semibold text-base text-contentTextColor'><Link to='/gallery'>Gallery</Link></li>  
          <li className='font-semibold text-base text-contentTextColor'><Link to='/contact'>Contact Us</Link></li>  
          </ul>
        </div>
        {/* <div className='hidden md:flex pr-4 mr-20'>
              <button className='px-8 py-2.5 bg-primaryColor rounded-full font-medium'>Consult Now</button>
        </div> */}
        <div className='md:hidden mr-4' onClick={handleClick}>
            {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
          
        </div> 
      </div>

      <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/' onClick={handleClose}>Home</Link></li>
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/about' onClick={handleClose}>About Us</Link></li>
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/trainingprogram' onClick={handleClose}>Training Programs</Link></li>
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/onlineprogram' onClick={handleClose}>Online Programs</Link></li>
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/immigrationprogram' onClick={handleClose}>Immigration Program</Link></li>
          {/* <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/studentvisa' onClick={handleClose}>Student Visa</Link></li> */}
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/gallery' onClick={handleClose}>Gallery</Link></li>
          <li className='font-semibold text-base border-b-2 border-zinc-300 w-full'><Link to='/contact' onClick={handleClose}>Contact Us</Link></li>

        {/* <div className='flex flex-col my-4'>
              <button className='px-8 py-2.5 bg-primaryColor rounded-full font-medium'>Consult Now</button>
            
        </div> */}
      </ul>
    </motion.div>
  );
};

export default Navbar;
