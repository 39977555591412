import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const heroAnimation = {
  visible: { y: 0, opacity: 1, transition: { delay: 0.3, duration: 0.8, type: "tween" } },
  hidden: { y: -50, opacity: 0 },
}

const SamplePrevArrow = (props) => {
    const { className, onClick } = props

    return (
        <div 
            className={className}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 md:h-16 md:w-16" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={1.1}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
        </div>
    );
}
  
const SampleNextArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            onClick={onClick}
        >
  
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 md:h-16 md:w-16" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={1.1}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg>
        </div>
    );
}

const Hero = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        fade: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              dots: false
            }
          },
        ]
    
      };

      const controls = useAnimation();
      const [ref, inView] = useInView();
    
      useEffect(() => {
          if(inView) {
              controls.start("visible");
          }
      }, [controls, inView]);

  return (
      <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={heroAnimation}
      id="home"
      >
      <Slider {...settings}>

      {props.values?.map((value) =>
      <div key={value.id} className='bannerColor w-full h-64 relative mt-[80px] lg:h-[calc(85vh-80px)]'>
      <img className='min-w-full h-64 absolute mix-blend-overlay object-cover object-center lg:h-full' src={value.featured_image} />

      <div
      className='flex flex-col relative mx-auto mt-20 lg:mt-1 lg:w-4/5 lg:py-52'
      >
      <h1 className="text-center text-white text-2xl font-medium tracking-wide leading-tight lg:tracking-normal lg:leading-snug md:text-[42px] md:pt-16">{value.title}</h1>
      {/* <button className='mx-auto my-4 px-4 py-2 lg:my-6 lg:px-8 lg:py-2 bg-primaryColor rounded-full font-normal'>Know More</button> */}
      </div>
      </div>
      )}
          </Slider>
      </motion.div>
  )
}

export default Hero